@import '@fontsource-variable/inter';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

:root {
	--title-font: 'Inter', sans-serif;
	--text-font: 'Roboto', sans-serif;
}

.ant-menu-submenu-title .ant-menu-title-content {
    font-size: 16px !important;
}

.draggable-parent1 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
  }

body {
  font-family: var(--text-font) !important;
}

html, body, #root {
	height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title-font);
}

.editable-cell {
	cursor: pointer;
  }
  
  .editable-cell:hover {
	background-color: #f0f0f0; /* Change this to your preferred hover color */
  }
  


/* disable autofill on input */
input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
	background-color: transparent !important;
}

.chart-container {
    max-height: 250px;
    height: 100%; /* Cambia da 250px a 100% per adattarsi alla props height */
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
}
.chart-container2 {
    max-height: 201px;
    height: 100%; /* Cambia da 250px a 100% per adattarsi alla props height */
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
}

.app-container {
    padding: 0px;
    font-family: Arial, sans-serif;
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
.no-data-container {
	height: 500px;
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 2px dashed #ccc;
	border-radius: 10px;
	background-color: #f9f9f9;
	color: #555;
	font-family: Arial, sans-serif;
	font-size: 18px;
	text-align: center;
	margin: 20px auto;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .no-data-text {
	margin: 0;
	padding: 0;
  }

  .grid-demo-chart-container {
	width: 100%;
	height: auto;
	padding: 10px;
	position: relative;
  }
  
  .grid-demo-no-data-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
	color: #999;
	font-size: 18px;
	text-align: center;
	border-style: dotted;
	margin-top: 20px;
	margin-bottom: 20px;
	
  }
  
  
  @keyframes blink {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0.5;
	}
	100% {
	  opacity: 1;
	}
  }
  
  .grid-demo-no-data-text {
	animation: blink 2s infinite;
	margin: 0;
	color: black;
  }
  
  .grid-demo-info-icon {
	margin-left: 10px;
  }
  
  .grid-demo-chart-container .MuiChartsGrid-root {
	z-index: 1; /* Ensure the grid is below the tooltip */
  }
  
  .grid-demo-chart-container .MuiTooltip-root {
	z-index: 9999 !important; /* Ensure tooltips are above all other elements */
  }
  
  .grid-demo-chart-container .MuiChartsAxis-label {
	font-weight: bold;
	font-size: 14px;
	fill: #333;
  }
  
  .grid-demo-chart-container .MuiChartsAxis-tickLabel {
	font-size: 12px;
	fill: #666;
  }
  
  .grid-demo-chart-container .MuiCharts-series {
	stroke-width: 2px; /* Thicker lines for better visibility */
  }
  
  .grid-demo-chart-container .MuiCharts-tooltip {
	background-color: rgba(255, 255, 255, 0.9);
	color: #000;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 4px;
	z-index: 9999; /* Ensure tooltips are above other elements */
  }
  
  .info-icon {
	font-size: 24px;
	color: #888;
	margin-top: 10px;
  }
.legend {
    margin-top: 20px;
    text-align: right;
    font-size: 18px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.container19 {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	/* To vertically align items */
}

/* Add this CSS to your project's stylesheet */

/* General container styling */
.signin-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
	height: 100vh;
	text-align: center;

	@media screen and (max-width: 580px) {
		background: black !important;
	}
}

/* Responsive adjustments */
@media (max-width: 600px) {
	.signin-container {
		flex-direction: column;
		padding: 20px;
		background-color: #000;
	}

	.signin-container .MuiGrid-item {
		text-align: center;
	}

	.signin-container .MuiBox-root {
		width: 100%;
	}

	.signin-container ul {
		padding-left: 0;
		text-align: center;
	}

	.signin-container img {
		margin: 0 auto;
	}
}


.signin-container h2 {
	color: #ffffff;
}

.signin-container ul {
	list-style: none;
	padding: 0;
	margin: 0 0 20px 0;
}

.signin-container ul li {
	margin: 10px 0;
}

.form-label {
	color: #ffffff;
}

.form-control {
	background-color: #2c2c2c;
	border: 1px solid #444;
	color: #ffffff;
}

.form-control::placeholder {
	color: #888;
}

.btn-primary {
	background-color: #4A90E2;
	border-color: #4A90E2;
}

.btn-primary:hover {
	background-color: #357ABD;
	border-color: #357ABD;
}


.chat-paper {
	background-color: #1E283B;
}

/* Hide sidebar on mobile by default */
/* Default styles for larger screens */
/* Default styles for larger screens */
.mobile-sidebar {
	position: sticky !important;
	top: 0;
	left: 0;
	height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ant-layout-sider-children {
	display: flex;
	flex-direction: column;
	
}


/* Hide sidebar on mobile by default */
@media (max-width: 768px) {
	.mobile-sidebar {
		position: fixed !important;
		left: -100vw !important;
		top: 0 !important;
		height: 100vh !important;
		width: 100vw !important;
		transition: left 0.3s ease-in-out !important;
		z-index: 1000 !important;
	}

	.fade-enter {
		opacity: 0;
	  }
	  
	  .fade-enter-active {
		opacity: 1;
		transition: opacity 0.5s ease-in;
	  }
	  
	  .fade-exit {
		opacity: 1;
	  }
	  
	  .fade-exit-active {
		opacity: 0;
		transition: opacity 0.5s ease-out;
	  }
	  
	.mobile-sidebar.visible {
		left: 0 !important;
	}

	.mobile-sidebar-toggle {
		display: none !important;
	}

	.mobile-sidebar-button {
		position: fixed !important;
		right: 0;
		width: inherit;
		z-index: 2;
		background-color:#0D47A1 !important;
		/* Button background color */
		color: white !important;
		margin-right: 20px !important;
		margin-top: 20px!important;
		/* Button text color */


	}

	@media (min-width: 769px) {
		.mobile-sidebar-button {
			display: none !important;
			left: -100px;
		}
	}
}

.chat-wrapper {
	height: 100%;
	padding: 10px;
	margin: 0px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 32px
}

/* @media (min-width: 1200px) {
	.chat-wrapper {

		left: -16px;
		width: calc(100% + 32px) !important;

	}
} */
.chat-wrapper-earth {
	height: 100%;
	padding: 4px;
	gap:10px;
	margin: 0px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	display: flex;
	/* flex-direction: column; */
	background: white;
	border-radius: 32px
}


@media (max-width: 600px) {
	.frame1 {
		flex-direction: column;
		/* Disporre gli elementi in colonna su schermi piccoli */
		align-items: stretch;
		/* Allinea gli elementi per riempire la larghezza */
	}

	.button {
		margin-top: 10px;
		/* Aggiungi spazio tra select e button */
	}

	.autocomplete-wrapper {
		width: 100%;
		/* Assicurati che la select prenda tutta la larghezza disponibile */
	}
}


.button1 {
	all: unset;
	border-radius: var(--shape-borderradius);
	align-items: center;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	padding: 8px 11px;
	position: relative;

}




.frame1 {
	align-items: center;
	border: 1px;
	border-style: groove;
	border-color: grey;
	border-radius: 8px;
	display: flex;
	gap: 16px;
	padding: 8px;

}

.frame .autocomplete-wrapper {
	align-items: center;
	display: flex;
	flex: 1;
	flex-grow: 1;
	gap: 24px;
	position: relative;
}

.frame .autocomplete {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
}

.frame .select-instance {
	align-self: stretch !important;
	flex: 0 0 auto !important;
	width: 100% !important;
}

.frame .design-component-instance-node {
	gap: 8px !important;
}

.frame .select-2 {
	height: 24px !important;
	left: 366px !important;
	position: absolute !important;
	top: -8px !important;
	width: 24px !important;
}

.frame .select-3 {
	top: 61px !important;
	width: 414px !important;
}

.frame .button {
	align-items: center;
	border-radius: var(--shape-borderradius);
	display: flex;
	flex-direction: column;
	height: 40px;
	justify-content: center;
	overflow: hidden;
	padding: 8px 11px;
	position: relative;
	width: 40px;
}

.frame .calendar-month {
	height: 24px;
	margin-left: -3px;
	margin-right: -3px;
	position: relative;
	width: 24px;
}





.white-text {
	color: white;
}

.ant-layout-sider {
	position: sticky;
   

}



.nav-link19 {
	color: rgb(4, 4, 134);
}

.custom-table th:nth-child(5),
.custom-table td:nth-child(5) {
	background-color: #05bf37;
}

.custom-table th:nth-child(6),
.custom-table td:nth-child(6) {
	background-color: #efef21;
}

.custom-table th:nth-child(8),
.custom-table td:nth-child(8) {
	background-color: #2ea9e2;
}

body.nav-menu-open {
	overflow: hidden;
}

.control-container {
	margin-top: 20px;
}

.custom-table th,
.custom-table td {
	text-align: center;
	vertical-align: middle;
}

.custom-table th {
	background-color: #f0f0f0;
}

.custom-table tbody tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.hidden-row {
	display: none;
}

.btn {
	border-radius: 20px;
	padding: 5px 20px;
	font-size: 14px;
	font-weight: bold;
}

.btn-outline-success {
	color: #28a745;
	border-color: #28a745;
}

.btn-outline-danger {
	color: #dc3545;
	border-color: #dc3545;
}

.btn:hover {
	opacity: 0.9;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

.home,
.services,
.products,
.sign-up {
	display: flex;
	height: 90vh;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

:root {
	--primary: #fff;
}

.cards {
	padding: 5rem;
	background-color: gainsboro;
}

.container {
	margin-top: 1rem;
}

h1 {
	text-align: center;
	font-size: xx-large;
}

td {
	vertical-align: middle;
	text-align: center !important;
}

th {
	vertical-align: middle;
	text-align: center !important;
	padding-left: 10px;
	padding-right: 10px;
	z-index: 1;
}

.sticky-thc {
	th {
		position: sticky !important;
		top: 0 !important;
	}
}

.full-width {
	width: 100% !important;
	padding-left: 0% !important;
	padding-right: 0% !important;
}

.pr-0 {
	padding-right: 0% !important;
}

.cards__container {
	display: flex;
	flex-flow: column;
	align-items: center;
	max-width: 1220px;
	width: 100%;
	margin: 0 auto;
}

.cards__wrapper {
	position: relative;
	margin: 50px 0 45px;
}

.cards__items {
	margin-bottom: 24px;
}

.cards__item {
	display: flex;
	flex: 1;
	margin: 0 1rem;
	border-radius: 10px;
}

.cards__item__link {
	background-image: "images/img-1.jpg";
	display: flex;
	flex-flow: column;
	width: 100%;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
	-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	border-radius: 10px;
	overflow: hidden;
	text-decoration: none;
}

.cards__item__pic-wrap {
	position: relative;
	width: 100%;
	padding-top: 67%;
	overflow: hidden;
}

.fade-img {
	animation-name: fade-img;
	animation-duration: 2s;
}

.cards__item__pic-wrap::after {
	content: attr(data-category);
	position: absolute;
	bottom: 0;
	margin-left: 10px;
	padding: 6px 8px;
	max-width: calc((100%) - 60px);
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background-color: #1f98f4;
	box-sizing: border-box;
}

.cards__item__img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
	transition: all 0.2s linear;
}

.cards__item__img:hover {
	transform: scale(1.1);
}

.cards__item__info {
	padding: 20px 30px 30px;
}

.cards__item__text {
	color: #252e48;
	font-size: 18px;
	line-height: 24px;
}

@media only screen and (min-width: 1200px) {
	.content__blog__container {
		width: 84%;
	}
}

@media only screen and (min-width: 1024px) {
	.cards__items {
		display: flex;
	}
}

@media only screen and (max-width: 1024px) {
	.cards__item {
		margin-bottom: 2rem;
	}
}

.footer-container {
	background-color: #1c1c1e;
	padding: 4rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	color: #fff;
}

.footer-line {
	position: absolute;
	inset: 0 0 0 0;
	height: 7px;

}

.footer-subscription {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
}

.footer-subscription form {
	max-width: 450px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.colored-text {
	font-size: 2em;
	background: linear-gradient(90deg, #ff0000, #00ff00, #0000ff);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

.article {
	background: radial-gradient(hsl(100 100% 60%), hsl(200 100% 60%)) fixed;
	background: conic-gradient(hsl(100 100% 60%),
			hsl(200 100% 60%),
			hsl(100 100% 60%)) fixed;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-align: center;
}

.mission-text {
	font-family: "Trebuchet MS" !important;
	font-weight: light !important;
	color: #fff;
}

.padding-box {
	padding-left: 4rem !important;
	padding-right: 4rem !important;
}

.learnmore-text {
	font-family: "Trebuchet MS" !important;
	font-size: 28px !important;
	font-weight: light !important;
	color: #fff;
}

.pl-low {
	padding-left: 10rem !important;
}

.pl-high {
	padding-left: 20rem !important;
}

.mission-container {
	background-color: rgb(100, 76, 76);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}




.signin-container {
	/* background: linear-gradient(to right, #fff 45%, #97d9e1 50%, #d9afd9 100%); */
	background: black;

	@media screen and (max-width: 580px) {
		background: green !important;
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 84px);
}

.signup-container {
	background: linear-gradient(to right, #fff 45%, #97d9e1 50%, #d9afd9 100%);

	@media screen and (max-width: 580px) {
		background: linear-gradient(to right, #fff 0%, #fff 100%) !important;
		margin-top: 1.5rem;
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 84px);
}

.gray-text {
	color: rgb(55, 55, 55) !important;
}

.aiuses-container {
	background: linear-gradient(to bottom, #fff 95%, #97d9e1 100%);
}

.learnform-container {
	background: linear-gradient(to bottom,
			rgb(28, 28, 28) 0%,
			rgb(54, 54, 54) 95%,
			#fff 100%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.howitworks-container {
	background-color: #d9afd9;
	background-image: linear-gradient(0deg, #97d9e1 0%, #d9afd9 5%, #97d9e1 100%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* .calltoaction-container {
	background-color: #97d9e1;
	background-image: linear-gradient(to bottom, #97d9e1 0%, #fff 20%) !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;
} */

.calltoaction-container {
	height: 100vh;
	width: 100%;
	background-image: url("./new-frontend/sfondoaimotor1.png");


	background-size: cover;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;
}



.CallToAction-text span {
	position: relative;
	overflow: hidden;
	display: block;
	line-height: 1.2;
	text-align: center;
	font-size: 24px;
	font-weight: light !important;
}

@media (max-width: 576px) {
	h2 {
		margin-top: 0.0005rem;
	}
}

h3 {
	margin-top: 0.0005rem;
}

.CallToAction-text span::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgb(233, 223, 223);
	animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
	transform: translateX(-101%);
	text-align: center;
	font-size: 24px;
	font-weight: light !important;
}

.CallToAction-text span::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: var(--bg-color);
	animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
	transform: translateX(0);
	text-align: center;
	font-size: 24px;
	font-weight: light !important;
}

.CallToAction-text span:nth-of-type(1)::before,
.CallToAction-text span:nth-of-type(1)::after {
	animation-delay: 1s;
}

.CallToAction-text span:nth-of-type(2)::before,
.CallToAction-textpan:nth-of-type(2)::after {
	animation-delay: 1.5s;
}

@keyframes a-ltr-after {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(101%);
	}
}

@keyframes a-ltr-before {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(200%);
	}
}

.footer-subscription>p {
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
	margin-bottom: 24px;
	font-size: 24px;
}

.footer-subscription-text {
	margin-bottom: 24px;
	font-size: 20px;
}

.footer-input {
	padding: 6px 16px;
	border-radius: 6px;
	outline: none;
	border: none;
	font-size: 14px;
	border: 1px solid #fff;
	width: 100%;
}

.footer-links {
	width: 100%;
	display: flex;
	justify-content: center;
}

.footer-link-wrapper {
	display: flex;
}

.footer-link-items {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
}

.clipped-image {
	width: 70%;
	margin-bottom: -10%;
}

.text-align {
	display: flex;
	align-items: center;
}

.footer-link-items h2 {
	margin-bottom: 16px;
}

.footer-link-items>h2 {
	color: #fff;
}

.footer-link-items a {
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
	color: #e9e9e9;
	transition: 0.3s ease-out;
}

.footer-email-form h2 {
	margin-bottom: 2rem;
}

.footer-input::placeholder {
	color: #b1b1b1;
}

.social-icon-link {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
}

.social-media {
	width: 100%;
}

.social-media-wrap {
	padding-top: 24px;
	border-top: 1px solid #383838;
}

.social-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.website-rights {
	color: #fff;
}

@media screen and (max-width: 820px) {
	.footer-input {
		width: 100%;
	}

	.btn {
		width: 100%;
	}

	.footer-link-wrapper {
		flex-direction: column;
	}
}

@media screen and (max-width: 768px) {}

video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	z-index: 0;
}

/*
video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -1;
}
*/

.video-landingPage-functions {
	display: none;
	/* Hide the img element */
	/*
	width: auto;
	height: 100%;
	transform: scale(0.8);
	transform-origin: center;*/
	/*
	object-fit: contain;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	*/
}

.video-container {
	position: relative;
	/* Adjust based on your layout needs */
	width: 100%;
	/* Full width */
	height: 60vh;
	/* Adjust based on your header/footer height */
	overflow: hidden;
	/* Hides anything outside this container */
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	/* Ensures the entire image fits within the container */
	background-repeat: no-repeat;
	/* Prevents the image from repeating */
	background-position: center;
	/* Centers the image within the container */

}


/* class to define the central text in HeroSection*/
.content-overlay {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	text-align: center;
	color: black;
	width: 100%;
	/* Set to 100% to take full width of the parent */
	max-width: 1100px;
	/* Adjust this value to control the maximum width */
	padding: 20px 40px;
	/* Adds padding around the text for better readability */
}

.LearnForm-overlay {
	text-align: center;
	color: white;
}

@import "@fontsource/roboto/300.css";

.hero-container {
	height: 100vh;
	width: 100%;
	/*background-image: url("./new-frontend/sfondoaimotor1.png");*/
	color: rgb(255, 255, 255);
	background-color: black;
	/*background: linear-gradient(to right, #007BFF 0%, #FFFFFF 60%, #6F42C1 100%);*/
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.hero-container>h1 {
	color: #fff;
	font-size: 72px;
	margin-top: -100px;
}

.hero-container>p {
	margin-top: 8px;
	color: #fff;
	font-size: 32px;
	font-weight: 300 !important;
}

/*
@media screen and (max-width: 960px) {
	.hero-container > h1 {
		font-size: 72px;
		margin-top: -150px;
	}
	.hero-container > p {
		font-size: 24px;
	}
}

@media screen and (max-width: 480px) {
	.hero-container > h1 {
		font-size: 44px;
		margin-top: -100px;
	}

	.hero-container > p {
		font-size: 24px;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;
	}

	.btn {
		width: 100%;
	}
}

@media screen and (max-width: 100px) {
	.hero-container > h1 {
		font-size: 29px;
		margin-top: -100px;
	}

	.hero-container > p {
		font-size: 16px;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;
	}

	.btn {
		width: 100%;
	}
}
*/
.hero-btns {
	margin-top: 32px;
	margin-bottom: 32px;

}

.hero-btns .btn {
	margin: 0 auto;
	width: auto;
	/* Ensure button does not take up full width */
	max-width: 300px;
	/* Set a maximum width for the button */
}

/* Media query for smaller screens */
@media screen and (max-width: 480px) {
	.hero-btns .btn {
		width: 30%;
		/* Adjust the width as needed */
		max-width: 30%;
		/* Ensure the button respects the screen size */
		font-size: 16px;
	}
}


/*
.fa-play-circle {
	margin-left: 4px;
}
*/
.mission-text {
	font-family: "Trebuchet MS" !important;
	font-weight: light !important;
	color: #fff;
}

h1 {
	color: #3498db;
}

.styled-table {
	border-collapse: collapse;
	width: 100%;
	margin-top: 20px;
	font-size: 16px;
	text-align: left;
}

.styled-table th,
.styled-table td {
	padding: 22px 25px;
	border-bottom: 1px solid #ddd;
}

.styled-table th {
	background-color: #3498db;
	color: #fff;
}

.styled-table tbody tr:hover {
	background-color: #f5f5f5;
}

.table-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.table-container {
	margin-bottom: 20px;
}

.table-container-message {
	overflow-x: auto; /* Abilita scrolling orizzontale */
	display: block; /* Forza il contenitore a blocco */
	max-width: 100%; /* Limita la larghezza */
	white-space: nowrap; /* Evita il wrapping del contenuto tabulare */
  }
.styled-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 10px;
	overflow: hidden;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table th,
.styled-table td {
	padding: 12px 15px;
	text-align: center;
	border-bottom: 1px solid #ddd;
}

.styled-table th {
	background-color: #4caf50;
	color: white;
}

.styled-table tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.text-white {
	color: white;
}

.navbar {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	background: black;
	/* changed from 1.2 to 1.0*/
	top: 0px;
	position: sticky !important;
	z-index: 1000;
	/* changed from 99 to -1*/


}

.navbar-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
}

.navbar-logo img {
	max-width: 130px;
	width: 100%;
}

.nav-menu {
	display: flex;
	flex-direction: column;
	/* align-items: end; */
	width: 100%;
	opacity: 1;
	transition: all 0.5s ease;
}

.nav-links-mobile {
	display: block;
	text-align: center;
	border-radius: 4px;
	width: 80%;
	text-decoration: none;
	background-color: transparent;
	color: #fff;
	padding: 14px 20px;
	border: 1px solid #fff;
	transition: all 0.3s ease-out;
}

.nav-links-mobile:hover {
	background: #fff;
	color: #242424;
	transition: all 0.3s ease;
}

.footerNew-logo img {
	max-width: 130px;
	width: 100%;
}

.gradient-text {
	background: linear-gradient(to right, #6F42C1, #a69ac0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-size: 72px;
}

.gradient-title {
	background: linear-gradient(to left, #6F42C1, #a69ac0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-family: var(--title-font);
}

@media screen and (max-width: 990px) {
	.gradient-text {
		background: linear-gradient(to right, #6F42C1, #a69ac0);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		font-size: 56px;
	}
}

@media screen and (max-width: 767px) {
	.gradient-text {
		background: linear-gradient(to right, #6F42C1, #a69ac0);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		font-size: 44px;
	}
}

@media screen and (max-width: 480px) {
	.gradient-text {
		background: linear-gradient(to right, #6F42C1, #a69ac0);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		font-size: 38px;
	}
}

/* General h4 styles */
h4.responsive-h4 {
	font-size: 24px;
	/* Default font size */
	color: #ffffff;
	/* Default color */
	margin: 12px 0;
	/* Default margin */
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
	.responsive-h4 {
		font-size: 20px !important;
		/* Adjust font size for smaller screens */
	}
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
	.responsive-h4 {
		font-size: 20px !important;
		/* Adjust font size for even smaller screens */
	}
}


.fa-typo3 {
	margin-left: 0.5rem;
	font-size: 1.8rem;
}

.nav-links {
	color: #ffffff;
	align-items: center;
	text-decoration: none;
}

.nav-links:hover {
	border-bottom: 4px solid #fff;
	transition: all 0.2s ease-out;
	margin-top: auto;
}

.fa-bars {
	color: #fff;
}

.body {
	background-color: linear-gradient(90deg,
			rgb(28, 27, 27) 0%,
			rgb(26, 23, 23) 100%);
}

.bg-black {
	background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
	max-width: 100%;
}

.menu-icon {
	display: block;
	font-size: 1.8rem;
	cursor: pointer;
	z-index: 2;
}

.homepage {
	height: 100vh;
	/* Set the initial height of the homepage */
	/* overflow-y: scroll; Enable vertical scrolling */
	padding-left: 0 !important;
	padding-right: 0 !important;
	/* overflow-x: hidden; */
}

.homepage-video {
	width: 100% !important;

	height: auto;
}

.bg-white {
	background-color: white !important;
}

.full-height-percent {
	height: 100% !important;
}

.full-height-vh {
	height: 85vh !important;
}

.td-large {
	min-width: 200px !important;
}

.curved-col {
	/* background: linear-gradient(to right, white 0%, #006E5F 5%) !important; */
	background-image: linear-gradient(to left,
			#d9afd9 0%,
			#97d9e1 90%,
			#fff 100%);
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.team-members-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		gap: 20px;
	  }

	.nav-links {
		text-align: center;
		padding: 3rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		background-color: #fff;
		color: #242424;
		border-radius: 0;
	}

	.navbar-logoMenu {
		background-image: url("NewLogo.png");
		background-size: contain;
		position: absolute;
		color: transparent;
		background-repeat: no-repeat;
		width: 165px;
		height: 100%;
		margin-top: 18px;
		align-items: center;

		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.fa-times {
		color: #fff;
		font-size: 2rem;
	}
}

.learn-more-text {
	text-transform: uppercase;
	background-image: linear-gradient(-225deg,
			#231557 0%,
			#44107a 29%,
			#ff1361 67%,
			#fff800 100%);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	color: #fff;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 2s linear infinite;
	display: inline-block;
	font-size: 190px;
}

@keyframes textclip {
	to {
		background-position: 200% center;
	}
}

.highlight {
	color: #00ff00;
	font-weight: bold;
}

.highlight2 {
	color: #fff;
	font-weight: bold;
}

/* Override */
.btn-primary,
.btn-primary:visited {
	background-color: #477b81 !important;
	border-color: #51a2ab !important;
}

.btn-primary:hover {
	background-color: rgb(67, 132, 139) !important;
}

.btn-primary:active {
	background-color: #e8eeef !important;
	border-color: #284f54 !important;
}

.popup {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	padding: 20px;
	text-align: center;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}

textarea {
	width: 100%;
	height: 100px;
	margin-bottom: 10px;
}

button {
	background-color: #fff5f5;

	padding: 10px 20px;
	border: none;
	cursor: pointer;
	border-radius: 50%;
	/* Apply border radius to make it circular */
}


.ellipse-image {
	position: relative;
}

.ellipse-image img {
	position: relative;
}

.ellipse-image img:nth-child(1) {
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.ellipse-image img:nth-child(2) {
	bottom: 0;
	left: 0;
}

.ellipse-image img:nth-child(3) {
	bottom: 0;
	right: 0;
}

.text-big {
	font-size: 32px !important;
}

.text-medium {
	font-size: 26px !important;
}

.text-small {
	font-size: 24px !important;
}

@media screen and (max-width: 960px) {
	.text-big {
		font-size: 24px !important;
	}

	.text-medium {
		font-size: 20px !important;
	}

	.text-small {
		font-size: 18px !important;
	}
}

@media screen and (max-width: 760px) {
	.text-big {
		font-size: 22px !important;
	}

	.text-medium {
		font-size: 18px !important;
	}

	.text-small {
		font-size: 16px !important;
	}
}

.floating-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: auto !important;
	padding: 5px;
	border-radius: 50%;
	cursor: pointer;
}

.sign-up-buttons {
	justify-content: end;
	gap: 25px;
	display: flex;
	align-content: center;
	z-index: 0;
}

.sign-up-buttons-logged {

	width: 330px;
	height: 50px;
	position: absolute;
	right: 7px;
	justify-content: center;
	gap: 45px;
	display: flex;
	align-content: center;
	padding-top: 5px;
	z-index: -1;
}




.Button-logout {
	z-index: 2;
}

.side-bar {
	align-items: center;
}

.side-bar-logo {
	background-image: url(./NewLogo.png);
	background-size: contain;
	background-repeat: no-repeat;
	min-height: 26.5px;
	width: 100px;
	align-items: center;
	display: flex;
	margin-left: 23px;
}

.side-bar-logo-quadrato {
	background-image: url(./logo512.png);
	background-size: contain;
	background-repeat: no-repeat;
	height: 26.5px;
	width: 100px;
	align-items: center;
	display: flex;
	margin-left: 23px;
}

.engineButtons {
	margin-top: 5px;
	z-index: 500;
	align-items:center;
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	gap: 8px 8px;
	position: relative;
}

.engineButt {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	flex-wrap: wrap;
	gap: 8px 8px;
	position: relative;
	width: 100%;

}

.textEngine {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	gap: 8px;
	position: relative;
	width: 100%;
	margin-bottom: 10px;
	margin-top: 10px;
}

.customizeText {
	flex: 0 0 auto !important;
	color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 266%;
	letter-spacing: 1px;
	text-transform: uppercase;

}

.graphContainer {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	gap: 40px;
	position: relative;
	width: 100%;
}

.custom-table {
	align-items: flex-start;
	display: inline-flex;
	flex: 0 0 auto;
	gap: 8px;
	position: relative;
	margin-bottom: 20px;
	margin-top: 20px;
}

.table-buttons {
	align-items: flex-start;
	display: flex;
	flex: 0 0 0 0auto;
	gap: 8px;
	position: relative;
	margin-bottom: 20px;

}


.graphs {


	padding: 7px;

	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-radius: 8px;

	/* background: var(--Color-Neutral-Background-colorBgLayout, #F5F5F5); */


}

.graphs2 {




	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-radius: 8px;






}
.graphs3 {


	padding: 10px;

	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-radius: 8px;






}
.analytics {}

.analytics .text-wrapper {
	color: #000000de;
	font-family: "Roboto-Regular", Helvetica;
	font-size: 66px;
	font-weight: 400;
	height: 488px;
	left: 0;
	letter-spacing: 0.25px;
	line-height: 420px;
	top: 0;
	display: flex;
	width: 972px;
	height: 42px;
	width: 972px;
	justify-content: flex-start;
}

.insert-container {
	position: relative;
}

.InsertDriver h6 {
	/* Stili CSS per tutti gli elementi <p1> all'interno di InsertDriver */
	color: black;
	/* Colore del testo */
	font-family: Arial, sans-serif;
	/* Tipo di carattere */
	font-size: 16px;
	/* Dimensioni del testo */
	margin: 10px 0;
	/* Margine superiore e inferiore */
}

.InsertDriver.text-wrapper {
	color: #000000de;
	font-family: "Roboto-Regular", Helvetica;
	font-size: 66px;
	font-weight: 400;
	height: 488px;
	left: 0;
	letter-spacing: 0.25px;
	line-height: 420px;
	position: fixed;
	top: 0;
	display: flex;
	width: 972px;
	height: 42px;
	width: 972px;
	justify-content: flex-start;
}

.box {
	align-items: flex-start;
	display: flex;
	position: relative;
}

.box .title-and-contents {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 24px;
	position: relative;
}

.box .div {
	align-self: stretch;
	color: var(--palette-text-primary);
	font-family: var(--typography-h4-font-family);
	font-size: var(--typography-h4-font-size);
	font-style: var(--typography-h4-font-style);
	font-weight: var(--typography-h4-font-weight);
	letter-spacing: var(--typography-h4-letter-spacing);
	line-height: var(--typography-h4-line-height);
	margin-top: -1px;
	position: relative;
}

.box .frame-2 {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	gap: 56px;
	position: relative;
	width: 100%;
}

.box .frame-3 {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	gap: 16px;
	position: relative;
	width: 100%;
}

.box .text-wrapper-2 {
	align-self: stretch;
	color: var(--palette-text-primary);
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	margin-top: -1px;
	position: relative;
	margin-bottom: 25px;
}

.box .frame-4 {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	gap: 10px;
	position: relative;
	width: 100%;
}

.box .frame-5 {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	position: relative;
	width: 100%;
}

.box .frame-6 {
	align-items: center;
	display: flex;
	flex: 1;
	flex-grow: 1;
	position: relative;
}

.box .frame-7 {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 8px;
	position: relative;
}

.box .text-wrapper-3 {
	color: var(--palette-text-secondary);
	font-family: "Roboto";
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.15px;
	line-height: 12px;
	margin-top: -1px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.box .content {
	align-items: center;
	display: flex;
	height: 24px;
	position: relative;
	min-width: 200px;
}

.box .min-height {
	height: 24px;
	position: relative;
	width: 1px;
}

.box .text-wrapper-4 {
	color: var(--palette-text-primary-duplicate);
	flex: 1;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 100;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.15px;
	margin-top: -1px;

	position: relative;

}

.box .design-component-instance-node {
	flex: 0 0 auto !important;
}

.box .frame-wrapper {
	align-items: center;
	display: flex;
	flex: 1;
	flex-grow: 1;
	gap: 24px;
	position: relative;
}

.box .frame-8 {
	flex: 1;
	flex-grow: 1;
	height: 41px;
	position: relative;
}

.box .frame-9 {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	gap: 16px;
	position: relative;
	width: 100%;
}

.box .personal-info {
	align-items: flex-start;

	border-radius: 12px;
	box-shadow: 0px 6px 16px #0000001f;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 28px;
	justify-content: flex-end;
	padding: 16px;
	position: relative;
}

.box .personal {
	align-items: flex-start;

	border-radius: 12px;
	box-shadow: 0px 6px 16px #04b6ec3c;
	display: flex;
	flex-direction: column;
	gap: 28px;
	justify-content: flex-end;
	padding: 16px;
	padding-bottom: 10px;
	position: relative;
	margin-right: -100px;
}

.box .title-description {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	gap: 8px;
	position: relative;
	width: 100%;
}

.box .text-wrapper-5 {
	color: var(--palette-text-primary-duplicate);
	font-family: "Roboto-SemiBold", Helvetica;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	margin-top: -1px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.box .text-wrapper-6 {
	text-transform: none;
	color: var(--customize-theme-color-neutral-text-colortextlabel);
	font-family: "Roboto-Regular", Helvetica;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 18px;
	align-items: flex-start;
	position: relative;
}

.box .img {
	height: 24px;
	position: relative;
	width: 24px;
}

.box .title-description-wrapper {
	align-items: flex-start;
	background-color: #ffffff01;
	border-radius: 12px;
	box-shadow: 0px 6px 16px #0000001f;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 28px;
	justify-content: flex-end;
	padding: 16px;
	position: relative;
}

.box .text-wrapper-7 {
	color: #222222;
	font-family: "Roboto-SemiBold", Helvetica;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	margin-top: -1px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.box .add-wrapper {
	align-items: center;
	align-self: stretch;
	background-color: #ffffff01;
	border: 1px dashed;
	border-color: var(--customize-theme-color-neutral-borders-colorborder);
	border-radius: 12px;
	box-shadow: 0px 6px 16px #0000001f;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 28px;
	height: 80px;
	justify-content: center;
	padding: 16px;
	position: relative;
}

.box .frame-10 {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 16px;
	margin-top: 20px;
	justify-content: flex-end;
	position: relative;
	width: 100%;

}

.box .account {
	align-self: stretch;
	color: var(--palette-text-primary);
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	position: relative;
}

.box .div-wrapper {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex: 1;
	flex-grow: 1;
	gap: 24px;
	position: relative;
}

.box .frame-11 {
	align-self: stretch;
	flex: 1;
	flex-grow: 1;
	position: relative;
}

.no-underline {
	text-decoration: none;
}

.no-underline:hover {
	text-decoration: none;
}

.change-name1 {
	

	
	align-items: flex-start;
	background-color: #001529;
	display: flex;
}
	

@media (min-width: 768px) {
	.change-name1 {

		top: 32px;
		right: -150px;
		align-items: flex-start;
		background-color: #001529;
		border-radius: 8px;
		display: flex;
		position: absolute;
		width: 400px;
		z-index: 100;
	}
}

@media (max-width: 768px) {
	.change-name1 {

		top: 32px;
		left: 10vw;
		align-items: flex-start;
		background-color: #001529;
		border-radius: 8px;
		display: flex;
		position: absolute;
		width: 300px;
		z-index: 100;
	}
}



.change-name1 .frame {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	padding: 24px 24px 32px 48px;
	position: relative;
}

.change-name1 .text-wrapper {
	color: #ffffffe0;
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	margin-top: -1px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}
.ant-menu-item, .ant-menu-item-only-child {
    padding-left: 25px !important;
	font-size: 15px !important;
}
.change-name1 .text-field-instance {
	align-self: stretch !important;
	flex: 0 0 auto !important;
	width: 100% !important;
	background-color: #2f485b;
}

.change-name1 .design-component-instance-node {
	top: 40px !important;
	width: 288px !important;
}

.change-name1 .icon-button-instance {
	flex: 0 0 auto !important;
	margin: 10px;
	z-index: 100;
}

.change-name1 .close-filled {
	height: 24px !important;
	position: relative !important;
	width: 24px !important;
}

.change-name {

	
	align-items: flex-start;
	background-color: #001529;
	display: flex;
	
}





.change-name .frame {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	padding: 24px 24px 32px 48px;
	position: relative;
}

.change-name .text-wrapper {
	color: #ffffffe0;
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	margin-top: -1px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.change-name .text-field-instance {
	align-self: stretch !important;
	flex: 0 0 auto !important;
	width: 100% !important;
	background-color: #2f485b;
}

.change-name .design-component-instance-node {
	top: 40px !important;
	width: 288px !important;
}

.change-name .icon-button-instance {
	flex: 0 0 auto !important;
	margin: 10px;
	z-index: 100;
}

.remove-icon:hover {
	color: red;
	cursor: 'pointer';
  }

.change-name .close-filled {
	height: 24px !important;
	position: relative !important;
	width: 24px !important;
}

.create-team {
	align-items: flex-start;
	background-color: #001529;
	
	display: flex;
}




.create-team .frame {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	padding: 30px 5px 32px 32px;
	position: relative;
}

.create-team .text-wrapper {
	color: #ffffffe0;
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	margin-top: -1px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.create-team .text-field-instance {
	align-self: stretch !important;
	flex: 0 0 auto !important;
	width: 100% !important;
	background-color: #2f485b;
}

.create-team .design-component-instance-node {
	top: 40px !important;
	width: 288px !important;
}

.create-team .icon-button-instance {
	flex: 0 0 auto !important;
	margin: 10px;
	z-index: 100;
}

.create-team .close-filled {
	height: 24px !important;
	position: relative !important;
	width: 24px !important;
}

.create-team .p {
	align-self: stretch;
	color: #ffffff;
	font-family: var(--typography-caption-font-family);
	font-size: var(--typography-caption-font-size);
	font-style: var(--typography-caption-font-style);
	font-weight: var(--typography-caption-font-weight);
	letter-spacing: var(--typography-caption-letter-spacing);
	line-height: var(--typography-caption-line-height);
	position: relative;
}

.create-team .button-wrapper {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	gap: 16px;
	justify-content: flex-end;
	position: relative;
	width: 100%;
}

.create-team .design-component-instance-node-2 {
	flex: 0 0 auto !important;

}

.elements-bars {
	align-items: flex-start;
	background-color: #001529;

	display: flex;
	
}




.elements-bars .frame {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	padding: 24px 24px 32px 15px;
	position: relative;
}

.elements-bars .div {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	gap: 10px;
	position: relative;
	width: 100%;
}

.elements-bars .text-field-instance {
	align-self: stretch !important;
	flex: 0 0 auto !important;
	width: 100% !important;
	color: white !important;
	background-color: #2f485b;
}

.elements-bars .p {
	color: #ffffffe0;
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	margin-bottom: -5px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.elements-bars .p1 {
	color: #ffffffe0;
	font-family: var(--typography-overline-font-family);
	font-size: var(--typography-overline-font-size);
	font-style: var(--typography-overline-font-style);
	font-weight: var(--typography-overline-font-weight);
	letter-spacing: var(--typography-overline-letter-spacing);
	line-height: var(--typography-overline-line-height);
	margin-top: -10px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.elements-bars .frame-2 {
	align-items: flex-end;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	gap: 10px;
	position: relative;
	width: 100%;
}

.elements-bars .text-field {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
}

.elements-bars .content {
	align-items: flex-start;
	align-self: stretch;
	background-color: var(--palette-components-input-filled-enabledfill-duplicate);
	border-radius: var(--shape-borderradius-duplicate) var(--shape-borderradius-duplicate) var(--shape-none) var(--shape-none);
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.elements-bars .input {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	gap: 3px;
	padding: 9px 12px 8px;
	position: relative;
	width: 100%;
}

.elements-bars .content-2 {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex: 0 0 auto;
	min-width: 24px;
	position: relative;
	width: 100%;
}

.elements-bars .min-height {
	height: 24px;
	position: relative;
	width: 1px;
}

.elements-bars .placeholder {
	background: transparent;
	border: none;
	color: var(--palette-text-disabled);
	flex: 1;
	font-family: var(--input-value-font-family);
	font-size: var(--input-value-font-size);
	font-style: var(--input-value-font-style);
	font-weight: var(--input-value-font-weight);
	letter-spacing: var(--input-value-letter-spacing);
	line-height: var(--input-value-line-height);
	margin-top: -1px;
	padding: 0;
	position: relative;
}

.elements-bars .underline {
	height: 1px;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 40px;
	width: 174px;
}

.elements-bars .select {
	align-items: flex-start;
	align-self: stretch;
	display: inline-flex;
	flex: 0 0 auto;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.elements-bars .content-3 {
	align-items: flex-start;
	background-color: var(--palette-components-input-filled-enabledfill);
	border-radius: var(--shape-borderradius) var(--shape-borderradius) var(--shape-none) var(--shape-none);
	display: inline-flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.elements-bars .input-2 {
	align-items: flex-start;
	display: inline-flex;
	flex: 1;
	flex-direction: column;
	flex-grow: 1;
	gap: 2px;
	justify-content: center;
	padding: 0px 12px;
	position: relative;
}

.elements-bars .content-4 {
	align-items: center;
	display: inline-flex;
	background-color: #2f485b;
	flex: 0 0 auto;
	min-height: 24px;
	min-width: 24px;
	position: relative;
}

.elements-bars .text-wrapper-2 {
	color: var(--palette-text-primary);
	font-family: "Roboto-Regular", Helvetica;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.14px;
	line-height: 20px;
	position: relative;
	white-space: nowrap;
	width: fit-content;
}

.elements-bars .icon-instance-node {
	height: 24px !important;
	position: relative !important;
	width: 24px !important;
}

.elements-bars .default {
	height: 1px;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 40px;
	width: 97px;
}

.elements-bars .button-wrapper {
	align-items: center;
	display: flex;
	margin-bottom: 15;
	padding-right: 30px;
	gap: 16px;
	justify-content: flex-end;
	position: relative;
	width: 100%;
}

.elements-bars .design-component-instance-node {
	flex: 0 0 auto !important;
	right: 0;
	margin-top: 20px;
	margin-right: 20px;
	z-index: 100;
	position: absolute;
}




.second-part-home {
	display: flex;
	justify-content: center;
	padding: 2rem 0;
	background-color: black;
	padding-bottom: 150px;
	/* Ensures full screen coverage */
	width: 100%;
	/* Full viewport width */

	/* Ensure it covers the full height of the viewport */
	/*overflow: hidden;*/
	position: relative;
	/* To avoid any scrollbars */
	box-sizing: border-box;
	/* Include padding in the element's total width and height */
}

.content-grid {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}

ù

.draggable-table-container {
    background: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    cursor: grab;
    overflow: auto;
    z-index: 100;
    touch-action: none; /* Prevent default touch behavior to allow custom scrolling */
}

.legend-table h3 {
    margin: 30px 0;
    color: #333;
    font-size: 16px;
}

.legend-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
	box-shadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
}

.legend-table th,
.legend-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.legend-table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

.table-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.chip-toggle {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
}

.content-box {
	background: #000000;
	/* Background color of the boxes */
	border-radius: 8px;
	padding: 1.5rem;
	display: flex;
	flex-direction: row;
	/* Ensures horizontal layout */
	align-items: center;
	width: 90%;
	/* Ensure content boxes are wide */
}

.content-gif {

	/* Larger gif */
	height: auto;
	border-radius: 8px;
	/* margin-right: 1rem; */
	/* Space between gif and text */
	
}

.text-content {
	width: 50%;
	/* Width of the text container */
}

button {
	margin-top: 1rem;
	/* Space above the button */
	padding: 0.5rem 1rem;
	/* Padding of the button */
	font-size: 1rem;
	/* Font size of the button */
}

.settings-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	/*position: absolute;*/
	width: 100%;

}

/* @media (max-height: 899px) {
	.settings-logo {
		bottom: 90px;
	}
}

@media (min-height: 900px) {
	.settings-logo {
		bottom: 150px;
	}
} */


.settings-logo1 {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	/* position: absolute; */
	width: 100%;
}

/* @media (max-height: 899px) {
	.settings-logo1 {
		bottom: 130px;
	}
}

@media (min-height: 900px) {
	.settings-logo1 {
		bottom: 190px;
	}
} */

.hover-effect {
	transition: opacity 0.3s;
}

.settings-logo:hover .hover-effect {
	opacity: 0.8;
}


.pay-pal {
	position: relative;
	margin: 25px 0px;
	padding: 15px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center !important;
	border: 1px solid white;
	background: white;
	border-radius: 8px;
}

.paypal-buttons {

	width: 50vw !important;


}

.custom-select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 10px;
	font-size: 16px;
	border-radius: 4px;
	min-width: 30px;
	cursor: pointer;
	outline: none;
	transition: border-color 0.3s ease-in-out;
}

.hover-tooltip {
    position: fixed;
    top: calc(50% - 20px); /* Adjusted top position to be closer to the center */
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 10; /* Ensure it's on top of other elements */
    background-color: white; /* Optional: Add a background color for better visibility */
    padding: 5px; /* Optional: Add some padding */
    border-radius: 4px; /* Optional: Add some border radius */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow */
}

.custom-select:focus {
	border-color: #007bff;
}

.custom-select::after {
	content: "▼";
	font-size: 12px;
	color: #333;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

/* Update the styles for the sidebar */
.side-bar .ant-menu-item,
.side-bar .ant-menu-submenu-title {
  color: white !important;
}

.side-bar .ant-menu-item:hover,
.side-bar .ant-menu-submenu-title:hover {
  color: #ffffff !important; /* Text color */
  background-color: #0099ff !important; /* Light blue background color */
}
.side-bar .ant-menu-item-selected {
  color: #ffffff !important;
  background-color: #333333 !important;
}

.side-bar .ant-menu-title-content {
  color: white !important;
}

/* Update icon colors in the sidebar */
.side-bar .ant-menu-item-icon,
.side-bar .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: white !important;
}

/* Update icon colors on hover */
.side-bar .ant-menu-item-icon:hover,
.side-bar .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
  color: #ffffff !important;
}


.select-wrapper {
	position: relative;
	display: flex;
	margin-bottom: 20px;
	align-items: center;
	justify-content: space-between !important;

}

/* Floating Button */
.floating-filter-button {
    position:sticky;
    bottom: 60px;
    right: -62vw;
    z-index: 1000;
    background-color: #0D47A1; /* Customize this color to your need */
    color: #FFF;
}

/* Filter Card */
.filter-card {
    position: fixed;
    bottom: 80px; /* Adjust to place above the button */
    right: 120px; /* Position to the left of the button */
    width: auto;
    max-width: 400px; /* Adjust for the content */
    background-color: #F4F6F8; /* Background color */
    color: #000;
    border-radius: 16px;
    z-index: 1100; /* Ensure it's above other elements */
}

.filter-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.hover-icon {
	display: none; /* Hide by default */
  }
  
  .parent-div:hover .hover-icon {
	display: block; /* Show on hover */
  }

  
  
  .form-group label {
	margin-bottom: 8px; /* Adjust the spacing below the label */
  }
  
  .form-control {
	flex-grow: 1; /* Allows the input to take the remaining space */
  }

 